import {
  KOSPageBox,
  KOSSelectedResult,
  KOSTabs,
} from "@foodology-co/alejandria";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import PageHeader from "app/components/PageHeader";
import StoreManagerDialog from "app/components/Store/Maganer";
import StoreSetUpDialog from "app/components/Store/SetUp/Dialog";
import OnOffScheduledTable from "app/components/Store/SetUp/OnOfScheduled/Table";
import StoreTable from "app/components/Store/Table";
import { useAppSelector } from "app/hooks/useAppSelector";
import { commons, scheduled, storeManager } from "app/i18n/types";
import { Scheduled } from "core/stores/entities/Scheduled";
import {
  Store,
  StoreStatus,
  StoreStatusType,
} from "core/stores/entities/Store";
import { getActivesScheduled } from "core/stores/repository/scheduled";
import { storesByQuery } from "core/stores/repository/storeRepo";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Actions, StoreModal } from "utils/modal";
import StoreFilters from "./Filters";
import OnOffArchiveDialog from "app/components/Store/SetUp/OnOfScheduled/ArchiveDialog";

interface Props {}

const StorePage: FunctionComponent<Props> = () => {
  const { t } = useTranslation();

  const [stores, setStores] = useState<Store[]>([]);
  const [filteredStores, setFilteredStores] = useState<Store[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [selectedCity, setSelectedCity] = useState<string>();
  const [selectedKitchen, setSelectedKitchen] = useState<string>();
  const [selectedStatus, setSelectedStatus] = useState<string>();
  const [selectedItems, setSelectedItems] = useState<KOSSelectedResult[]>([]);
  const [selectedArchiveItems, setSelectedArchiveItems] = useState<
    KOSSelectedResult[]
  >([]);
  const [openSetUpDialog, setOpenSetUpDialog] = useState<boolean>(false);
  const [openOnOffDialog, setOpenOnOffDialog] = useState<boolean>(false);
  const [modal, setModal] = useState<StoreModal>();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [scheduledList, setScheduledList] = useState<Scheduled[]>([]);
  const [filteredScheduled, setFilteredScheduled] = useState<Scheduled[]>([]);

  const session = useAppSelector((state) => state.session.data);

  const getData = () => {
    setOpenSetUpDialog(false);
    setSelectedItems([]);
    setStores([]);

    setLoading(true);
    storesByQuery({
      cursor: { limit: 100000 },
      params: {
        include: (selectedStatus
          ? [selectedStatus]
          : Object.keys(StoreStatus)) as StoreStatusType[],
        location: {
          country: selectedCountry,
          city: selectedCity,
          kitchen: selectedKitchen,
        },
        companyId: session?.companyId ?? "",
      },
      select: ["legacy_config", "legacy_schedule", "statusReason"],
    })
      .then((response) => {
        setStores(response.list);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getScheduledData = () => {
    setSelectedArchiveItems([]);
    setLoading(true);
    getActivesScheduled()
      .then((response) => {
        setScheduledList(response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedCountry) {
      const filtered = scheduledList.filter(
        (scheduled) => scheduled.country === selectedCountry
      );
      setFilteredScheduled(filtered);
    } else {
      setFilteredScheduled(scheduledList);
    }
  }, [selectedCountry, scheduledList]);

  useEffect(() => {
    if (tabIndex === 0) getData();
    setFilteredScheduled(
      scheduledList.filter((el) => {
        return !selectedCountry || el.country === selectedCountry;
      })
    );
  }, [selectedCountry, selectedCity, selectedKitchen, selectedStatus]);

  useEffect(() => {
    if (modal?.action === Actions.config) {
      setOpenSetUpDialog(true);
    }
    if (modal?.action === Actions.on_off) {
      setOpenOnOffDialog(true);
    }
  }, [modal]);

  const onCloseModal = () => setModal(undefined);

  const onRefresh = () => {
    if (tabIndex === 0) getData();
    if (tabIndex === 1) getScheduledData();
  };

  useEffect(() => {
    onRefresh();
  }, [tabIndex]);

  return (
    <KOSPageBox>
      <PageHeader
        title={storeManager.TITLE}
        rightArea={[
          {
            children: t(commons.CREATE),
            variant: "contained",
            startIcon: <AddOutlinedIcon />,
            show: tabIndex === 0,
            onClick: () =>
              setModal({
                open: true,
                action: Actions.add,
                onClose: onCloseModal,
              }),
          },
          {
            children: t(commons.SET_UP),
            variant: "outlined",
            color: "warning",
            startIcon: <SettingsOutlinedIcon />,
            onClick: () => setOpenSetUpDialog(true),
            disabled: selectedItems.length === 0,
            show: tabIndex === 0,
          },
          {
            children: t(scheduled.ARCHIVE),
            variant: "outlined",
            color: "error",
            startIcon: <ArchiveOutlinedIcon />,
            onClick: () => setOpenOnOffDialog(true),
            disabled: selectedArchiveItems.length === 0,
            show: tabIndex === 1,
          },
          {
            children: t(commons.UPDATE),
            variant: "outlined",
            startIcon: <RestartAltOutlinedIcon />,
            onClick: onRefresh,
          },
        ]}
      />
      <StoreFilters
        stores={stores}
        country={{ onChange: setSelectedCountry, value: selectedCountry }}
        city={{ onChange: setSelectedCity, value: selectedCity }}
        kitchen={{ onChange: setSelectedKitchen, value: selectedKitchen }}
        status={{ value: selectedStatus, onChange: setSelectedStatus }}
        setFilteredStores={setFilteredStores}
        loading={loading}
      />

      <KOSTabs
        tabs={[
          {
            children: {
              text: t(storeManager.STORES),
            },
          },
          {
            children: {
              text: t(storeManager.SCHEDULED),
            },
          },
        ]}
        loading={loading}
        onChange={setTabIndex}
      />

      {tabIndex === 0 && (
        <StoreTable
          filteredStores={filteredStores}
          loading={loading}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setModal={setModal}
        />
      )}

      {tabIndex === 1 && (
        <OnOffScheduledTable
          filteredScheduled={filteredScheduled}
          loading={loading}
          selectedItems={selectedArchiveItems}
          setSelectedItems={setSelectedArchiveItems}
          setModal={setModal}
        />
      )}

      {openOnOffDialog && (
        <OnOffArchiveDialog
          items={modal?.item ? [modal.item] : selectedArchiveItems}
          onClose={() => {
            setOpenOnOffDialog(false);
            setModal(undefined);
          }}
          onSuccess={getScheduledData}
        />
      )}

      {modal?.open && (
        <StoreManagerDialog
          {...modal}
          country={selectedCountry}
          city={selectedCity}
          kitchenId={selectedKitchen}
          onClose={onCloseModal}
          onSuccess={getData}
        />
      )}

      {openSetUpDialog && (
        <StoreSetUpDialog
          items={modal?.item ? [modal.item] : selectedItems}
          onClose={() => {
            setOpenSetUpDialog(false);
            setModal(undefined);
          }}
          onSuccess={getData}
        />
      )}
    </KOSPageBox>
  );
};

export default StorePage;
