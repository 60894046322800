import React, { useState, useCallback, useEffect } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  IconButton,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import es from "date-fns/locale/es";
import { BaseDialogProps, handleValidations } from "./utils";
import { commons, reasonOnOff, alertMessage } from "app/i18n/types";
import { useTranslation } from "react-i18next";
import { Action, ScheduledMassive } from "core/stores/entities/Scheduled";
import { createScheduledMassive } from "core/stores/repository/scheduled";
import { ManagerDialogFooter } from "app/components/common/Manager/Dialog/Footer";
import { useAllCountries } from "app/hooks/useAllCountries";
import { useAlert } from "app/hooks/useAlert";
import { reasonsOnOff } from "core/stores/entities/Store";
import { useModal } from "app/hooks/useModal";
import { handleModalIcon } from "utils/modal";

interface Props extends BaseDialogProps {}

export const HandleForm = (props: Props) => {
  const { t } = useTranslation();
  const { items, onClose } = props;

  const useCountries = useAllCountries();
  const alert = useAlert();
  const modal = useModal();

  const [selectedAction, setSelectedAction] = useState<string>("");
  const [selectedReason, setSelectedReason] = useState<string>();
  const [selectedDateTime, setSelectedDateTime] = useState<Date | null>(
    () => new Date()
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleActionChange = (event: SelectChangeEvent<string>) => {
    setSelectedAction(event.target.value);
  };

  const handleReasonChange = (event: SelectChangeEvent<string>) => {
    setSelectedReason(event.target.value);
  };

  const handleDateTimeChange = (date: Date | null) => {
    setSelectedDateTime(date);
  };

  useEffect(() => {
    setSelectedReason(undefined);
  }, [selectedAction]);

  useEffect(() => {
    modal.validator(handleValidations, {
      action: selectedAction,
      reason: selectedReason,
      date: selectedDateTime?.toString(),
    });
  }, [selectedAction, selectedReason, selectedDateTime]);

  const handleSubmit = useCallback(async () => {
    if (
      !handleValidations.isValidSync({
        action: selectedAction,
        reason: selectedReason,
        date: selectedDateTime?.toString(),
      })
    ) {
      return;
    }
    if (!selectedDateTime) return;

    setIsLoading(true);
    const parts = selectedDateTime.toString().split(" GMT");
    const date =
      new Date(`${parts[0]} GMT-0000`)
        .toISOString()
        .replace(".000Z", "")
        .replace("T", " ")
        .slice(0, -3) + ":00";

    const list = items.map((store) => {
      const timeZone = useCountries.byCode(store["country"])?.zone;
      return {
        storeId: store["id"],
        kitchenId: store["kitchenId"],
        country: store["country"],
        brandId: store["brandId"],
        timeZone: timeZone ?? "",
        platform: store["platform"],
      } as ScheduledMassive;
    });

    createScheduledMassive({
      list,
      date,
      action: selectedAction as Action,
      reason: selectedReason as reasonOnOff,
    })
      .then((response) => {
        if (response) {
          alert.success();
        } else {
          alert.error();
        }
      })
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  }, [selectedAction, selectedReason, selectedDateTime, onClose]);

  const reasons = (reasonsOnOff[`TURN_${selectedAction}`] ?? []) as string[];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <Dialog open={true} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h6" component="div">
              {t(commons.SCHEDULE)} {t(commons.ON)} / {t(commons.OFF)}
            </Typography>
          </Box>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ position: "relative", padding: 2 }}>
            <Grid container spacing={3}>
              {isLoading && (
                <Grid item xs={12}>
                  <LinearProgress />
                </Grid>
              )}

              <Grid item xs={12}>
                <Box
                  sx={{
                    backgroundColor: "#e3f2fd",
                    p: 1,
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <InfoOutlinedIcon color="info" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    {t(alertMessage.ROWS_SELECTED, { rows: items.length })}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <DateTimePicker
                  label={t(commons.DATE)}
                  value={selectedDateTime}
                  onChange={handleDateTimeChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{t(commons.ACTION)}</InputLabel>
                  <Select
                    value={selectedAction}
                    onChange={handleActionChange}
                    endAdornment={handleModalIcon(modal.errors["action"])}
                    label={t(commons.ACTION)}
                  >
                    <MenuItem value="ON">{t(commons.TURN_ON)}</MenuItem>
                    <MenuItem value="OFF">{t(commons.TURN_OFF)}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>{t(commons.REASON)}</InputLabel>
                  <Select
                    value={selectedReason}
                    onChange={handleReasonChange}
                    label={t(commons.REASON)}
                    endAdornment={handleModalIcon(modal.errors["reason"])}
                    disabled={!selectedAction}
                  >
                    {reasons.map((option: string) => (
                      <MenuItem key={option} value={option}>
                        {t(`reasonOnOff.${option}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <Grid item xs={12} sx={{ padding: 2 }}>
          <ManagerDialogFooter
            onCancel={props.onClose}
            mainButton={{
              children: t(commons.SAVE),
              onClick: handleSubmit,
              disabled: isLoading || !!Object.keys(modal.errors).length,
            }}
          />
        </Grid>
      </Dialog>
    </LocalizationProvider>
  );
};
