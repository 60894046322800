import { StoreAvailability } from "core/stores/entities/Store";
import { BaseModal } from "utils/modal";
import { commons } from "app/i18n/types";
import { object, string } from "yup";
import { KOSSelectedResult } from "@foodology-co/alejandria";

export interface BaseDialogProps extends BaseModal {
  items: KOSSelectedResult[];
}

export interface FormState {
  ids: string[];
  availability?: StoreAvailability;
  reason: string;
}

export const handleValidations = object({
  date: string().required(commons.REQUIRED_FIELD),
  action: string().required(commons.REQUIRED_FIELD),
  reason: string().required(commons.REQUIRED_FIELD),
});
