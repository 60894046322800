import appConfig from "config/app";
import { ApiVersion, getHttp, postHttp } from "utils/http";
import {
  ArchivePayload,
  Scheduled,
  ScheduledMassivePayload,
  UpdateStatus,
} from "../entities/Scheduled";

export const getAllScheduled = async (): Promise<Scheduled[]> => {
  try {
    const response = await getHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/on-off/scheduled/all`
    );
    const data = await response.json();
    return data.data as Scheduled[];
  } catch (ex: any) {
    console.error(`Error getting all scheduled | ${ex.message}`);
    return [] as Scheduled[];
  }
};

export const getActivesScheduled = async (): Promise<Scheduled[]> => {
  try {
    const response = await getHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/on-off/scheduled/actives`
    );
    const data = await response.json();
    return data.data as Scheduled[];
  } catch (ex: any) {
    console.error(`Error getting actives scheduled | ${ex.message}`);
    return [] as Scheduled[];
  }
};

export const archive = async (payload: ArchivePayload): Promise<void> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/on-off/scheduled/archive`,
      payload
    );

    if (!response.ok) {
      throw new Error(
        `Failed to archive for schedules: ${JSON.stringify(payload.ids)}`
      );
    }

    console.info(
      `Archive successfully for scheduled ${JSON.stringify(payload.ids)}`
    );
  } catch (ex: any) {
    console.error(`Error updating status | ${ex.message}`);
  }
};

export const updateStatus = async (updateData: UpdateStatus): Promise<void> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/on-off/scheduled/status`,
      updateData
    );

    if (!response.ok) {
      throw new Error(
        `Failed to update status for scheduled with id: ${updateData.id}`
      );
    }

    console.info(
      `Status updated successfully for scheduled with id ${updateData.id}`
    );
  } catch (ex: any) {
    console.error(`Error updating status | ${ex.message}`);
  }
};

export const createScheduled = async (scheduled: Scheduled): Promise<void> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/on-off/scheduled/create`,
      scheduled
    );

    if (!response.ok) {
      throw new Error(`Failed to crearte scheduled`);
    }

    console.info(`Scheduled crearte successfully`);
  } catch (ex: any) {
    console.error(`Error crearting scheduled | ${ex.message}`);
  }
};

export const createScheduledMassive = async (
  scheduled: ScheduledMassivePayload
): Promise<boolean> => {
  try {
    const response = await postHttp(
      appConfig.bistroKeeper.apiBaseUrl,
      ApiVersion.V1,
      `store/on-off/scheduled/create/massive`,
      scheduled
    );
    const result: boolean = await response.json();
    return result;
  } catch (ex: any) {
    console.error(`Error crearting scheduled massive | ${ex.message}`);
    return false;
  }
};
