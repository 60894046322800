import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  LinearProgress,
} from "@mui/material";
import { commons, scheduled } from "app/i18n/types";
import { archive } from "core/stores/repository/scheduled";
import { KOSSelectedResult } from "@foodology-co/alejandria";

interface ArchiveDialogProps {
  items: KOSSelectedResult[];
  onClose: () => void;
  onSuccess: () => void;
}

const OnOffArchiveDialog: React.FunctionComponent<ArchiveDialogProps> = (
  props
) => {
  const { items, onClose, onSuccess } = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const handleDelete = () => {
    if (!items.length) return;
    setLoading(true);
    archive({ ids: items.map((el) => el.id) })
      .then(() => {
        onSuccess();
        onClose();
      })
      .catch((error) => {
        console.error("Error deleting scheduled item:", error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>{t(scheduled.ARCHIVE)}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {t(scheduled.CHANGE_STATUS_MESSAGE, { nro: items.length })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t(commons.CANCEL)}
        </Button>
        <Button
          onClick={handleDelete}
          color="primary"
          autoFocus
          disabled={loading}
        >
          {t(commons.ACCEPT)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OnOffArchiveDialog;
