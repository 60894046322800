import React, { FunctionComponent, useCallback } from "react";
import {
  KOSBaseTable,
  KOSBaseTableHeader,
  KOSRowData,
  KOSSelectedResult,
} from "@foodology-co/alejandria";
import { commons, reasonOnOff } from "app/i18n/types";
import { Scheduled } from "core/stores/entities/Scheduled";
import { useTranslation } from "react-i18next";
import { Box, IconButton } from "@mui/material";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import { formatDate } from "utils/date";
import { Actions, StoreModal } from "utils/modal";

interface Props {
  filteredScheduled: Scheduled[];
  loading: boolean;
  selectedItems: KOSSelectedResult[];
  setSelectedItems: (selectedItems: KOSSelectedResult[]) => void;
  setModal: (modal: StoreModal) => void;
}

const OnOffScheduledTable: FunctionComponent<Props> = (props) => {
  const {
    filteredScheduled,
    loading,
    selectedItems,
    setSelectedItems,
    setModal,
  } = props;
  const { t } = useTranslation();

  const getActions = (row: KOSRowData) => {
    if (row.status === "PENDING") {
      return (
        <IconButton
          color="primary"
          onClick={() =>
            setModal({
              item: {
                id: row["id"],
              },
              action: Actions.on_off,
              onClose: () => {},
            })
          }
        >
          <ArchiveOutlinedIcon />
        </IconButton>
      );
    }
  };

  const getHeader = useCallback((): KOSBaseTableHeader[] => {
    return [
      { label: t(commons.STORE), field: "storeId" },
      { label: t(commons.KITCHEN), field: "kitchenId" },
      { label: t(commons.COUNTRY), field: "country" },
      { label: t(commons.BRAND), field: "brandId" },
      { label: t(commons.PLATFORM), field: "platform" },
      {
        label: t(commons.DATE),
        text: (row) => formatDate(row.date, row.timeZone),
      },
      {
        label: t(commons.REASON),
        text: (row) => t(reasonOnOff[row.reason as keyof typeof reasonOnOff]),
      },
      {
        label: t(commons.ACTION),
        text: (row) => t(`common.TURN_${row.action}`),
      },
      {
        label: t(commons.STATUS),
        text: (row) => t(`StatusLocale.${row.status}`),
      },
      { label: t(commons.TIME_ZONE), field: "timeZone" },
      {
        label: t(commons.ACTION),
        align: "center",
        component: (row) => getActions(row),
      },
    ];
  }, [filteredScheduled]);

  return (
    <Box mt={2}>
      <KOSBaseTable
        columns={getHeader()}
        pagination={{ enable: true }}
        rows={{
          data: filteredScheduled,
          total: filteredScheduled.length ?? 0,
          loading: !!loading,
        }}
        selectable={{
          canSelectAll: true,
          resultHeader: ["id"],
          items: selectedItems,
          onChange: setSelectedItems,
        }}
      />
    </Box>
  );
};

export default OnOffScheduledTable;
